import React from "react"
import { css } from "@emotion/core"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import {
  faAmazon,
  faApple,
  faSoundcloud,
  faSpotify,
} from "@fortawesome/free-brands-svg-icons"
import { graphql, Link, useStaticQuery } from "gatsby"

import Layout from "../../components/layout/default"

export default () => {
  const { site } = useStaticQuery(query)
  return (
    <Layout title={site.siteMetadata.title}>
      <div
        css={css`
          ul {
            margin-left: 0;
          }
          li {
            list-style-type: none;
            & > div {
              display: none;
            }
            &:hover > div {
              display: inline-block;
            }
          }
        `}
      >
        <h1>{site.siteMetadata.title}</h1>
        <ul>
          <li>
            <Link to="/shushi">種子</Link>
            {` `}
            <div>
              <a
                title="Amazon"
                href="https://www.amazon.co.jp/dp/B000P6XQ5K"
                target="_"
              >
                <Icon icon={faAmazon} fixedWidth />
              </a>
              {` `}
              <a
                title="Apple Music"
                href="https://music.apple.com/jp/album/%E7%A8%AE%E5%AD%90/256770622?i=256770989"
                target="_"
              >
                <Icon icon={faApple} fixedWidth />
              </a>
              {` `}
              <a
                title="Soundcloud"
                href="https://soundcloud.com/shishidohinata/shushi"
                target="_"
              >
                <Icon icon={faSoundcloud} fixedWidth />
              </a>
            </div>
          </li>
          <li>
            <Link to="/harumushi">春虫</Link>
            {` `}
            <div>
              <a
                title="Amazon"
                href="https://www.amazon.co.jp/dp/B009PN1PVG"
                target="_"
              >
                <Icon icon={faAmazon} fixedWidth />
              </a>
              {` `}
              <a
                title="Spotify"
                href="https://open.spotify.com/track/0634qnhE3GzYwsDq9wpTdq?si=-dFZDj6GS8SZDO8oyhpx9g"
                target="_"
              >
                <Icon icon={faSpotify} fixedWidth />
              </a>
              {` `}
              <a
                title="Soundcloud"
                href="https://soundcloud.com/shishidohinata/harumushi"
                target="_"
              >
                <Icon icon={faSoundcloud} fixedWidth />
              </a>
            </div>
          </li>
        </ul>
      </div>
    </Layout>
  )
}

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        twitterUsername
      }
    }
  }
`
